<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{formAction}} Organisation</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field label="Organisation Name" v-model="organisation.name" required></v-text-field>
            <v-text-field label="Phone" type="phone" v-model="organisation.phone" required></v-text-field>
            <v-text-field label="URL" type="url" v-model="organisation.url" required></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closeModal')">Cancel</v-btn>
        <v-btn @click="submit">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import toastr from 'toastr'

export default {
  props: ['formAction', 'modelId'],
  metaInfo: {
    title: 'Organisation'
  },
  data() {
    return {
      valid: false,
      organisation: {
        name: '',
        phone: '',
      },
      errors: []
    }
  },
  methods: {
    getOrganisation() {
      if(this.modelId !== null) {
        this.$store.dispatch('organisations/get', this.modelId).then((result) => {
              this.organisation = result;
            }
        );
      }
    },
    submit() {
      if(this.modelId !== null) {
        this.$store.dispatch('organisations/edit', {
          name: this.organisation.name,
          url: this.organisation.name,
          phone: this.organisation.name
        }).then((result) => {
          this.$emit('closeModal')
        }).catch((error) => {
          console.log(error);
        })
      } else {
        this.$store.dispatch('organisations/create', {
          name: this.organisation.name,
          url: this.organisation.name,
          phone: this.organisation.name
        }).then((result) => {
          toastr.success('Successfully added an organisation.')
          this.$emit('closeModal')
        }).catch((error) => {
          console.log(error);
        })
      }
    },
    mounted() {
      this.getOrganisation();
    },
  },

}
</script>
