<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn color="success" @click.stop="createModal()">
              Add Organisation
            </v-btn>
            <v-dialog
                v-model="modalShow"
                max-width="600px"
            >
              <ModalForm v-if="modalShow" :model-id="modalId" :form-action="modalAction" :closeModal="modalShow = false"></ModalForm>
            </v-dialog>
          </div>
        </v-card-title>
        <v-card-title>
          <strong><v-icon x-large>mdi-office-building-outline</v-icon></strong> Organisations
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="organisations"
            item-key="name"
            show-select
            class="elevation-1 table-one"
            multi-sort
        >
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-card-title>
                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="editModal(item.id)">
                  Edit
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="deleteItem(item.id)">
                  Remove
                </v-btn>
              </v-card-title>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import ModalForm from "./OrganisationModal";
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ModalForm
  },
  metaInfo: {
    title: 'Organisations'
  },
  data() {
    return {
      modalId: '',
      modalAction: 'add',
      modalShow: 'true',
      search: '',
      selected: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Phone',
          sortable: true,
          value: 'phone',
          align: 'left',
        },
        {
          text: 'Url',
          sortable: true,
          value: 'url',
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('organisations', {loading : 'isFindPending'}),
    ...mapGetters('organisations', {findOrganisationsInStore : 'find'}),

    organisations() {
      return this.findOrganisationsInStore().data || [];
    }
  },
  methods: {
    ...mapActions('organisations', { findOrganisations: 'find' }),

    createModal() {
      console.log("creating");
      this.modalId = null;
      this.modalAction = "add"
      this.modalShow = true
    },

    editModal(id) {
      this.modalId = id;
      this.modalAction = "edit"
      this.modalShow = true
    },

    deleteItem(id) {
      this.$store.dispatch('organisations/remove', id)
    }
  },
  created() {
    this.findOrganisations({ query: {} })
  },
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
